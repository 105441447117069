import LZString from 'lz-string';

// return the user data from the local storage
export const getUser = () => {
  const userStr = decryptData(localStorage.getItem("userInfo"));
  if (userStr && userStr !== 'undefined') return userStr;
  else return null;
};

// return the token from the local storage
export const getToken = () => {
  return decryptData(localStorage.getItem("token")) || null;
  // return null;
};

// set the token into the local storage
export const setToken = (token) => {
  token = encryptData(token);
  localStorage.setItem("token", token);
};

// remove the token and user from the local storage
export const removeUserSession = () => {
  localStorage.removeItem("userMenuInfo");
  localStorage.removeItem("masterData");
  localStorage.removeItem("statesInfo");
  localStorage.removeItem("oldFormValues");
  localStorage.removeItem("formValues");
  localStorage.removeItem("formValuesViaClass");
  localStorage.removeItem("fdukInfo");
  localStorage.removeItem("countInfo");
  localStorage.removeItem("announcementInfo");
  localStorage.removeItem("registeredUsers");
  localStorage.removeItem("questionAnsIsLegacyUpload");
  localStorage.removeItem("removeAllApiResponse");
  localStorage.removeItem("currentCoordinates");
};

// remove the token and user from the local storage
export const clearUserVerificationSession = () => {
  localStorage.removeItem("userInfo");
  localStorage.removeItem("userMenuInfo");
  localStorage.removeItem("masterData");
  localStorage.removeItem("statesInfo");
  localStorage.removeItem("oldFormValues");
  localStorage.removeItem("formValues");
  localStorage.removeItem("formValuesViaClass");
  localStorage.removeItem("fdukInfo");
  localStorage.removeItem("countInfo");
  localStorage.removeItem("announcementInfo");
  localStorage.removeItem("registeredUsers");
  localStorage.removeItem("questionAnsIsLegacyUpload");
  localStorage.removeItem("removeAllApiResponse");
  localStorage.removeItem("currentCoordinates");
};

// remove the token and user from the local storage
export const removeAllStorage = () => {
  localStorage.clear();
};

// set the user from the local storage
export const removeUserInfo = () => {
  localStorage.removeItem("userInfo");
};

// set the token and user from the local storage
export const setUserSession = (userInfo = null) => {
  userInfo = encryptData(userInfo);
  localStorage.setItem("userInfo", userInfo);
};

// remove the Initial info from the local storage
export const removeInitialInfo = () => {
  localStorage.removeItem("initialInfo");
};

// set the Initial info from the local storage
export const setInitialInfo = (initialInfo = null) => {
  initialInfo = encryptData(initialInfo);
  localStorage.setItem("initialInfo", initialInfo);
};

// get Initial info 
export const getInitialInfo = () => {
  const initialInfo = decryptData(localStorage.getItem("initialInfo"));
  if (initialInfo && initialInfo !== 'undefined') return initialInfo;
  else return null;
};

// remove the token and user from the local storage
export const removeUserBasicInfo = () => {
  localStorage.removeItem("userBasicInfo");
};

// set the token and user from the local storage
export const setUserBasicInfo = (userBasicInfo = null) => {
  userBasicInfo = encryptData(userBasicInfo);
  localStorage.setItem("userBasicInfo", userBasicInfo);
};

// get user basic info 
export const getUserBasicInfo = () => {
  const userStr = decryptData(localStorage.getItem("userBasicInfo"));
  if (userStr && userStr !== 'undefined') return userStr;
  else return null;
};

// set the menu from the local storage
export const setUserMenuInfo = (userMenuInfo = null) => {
  userMenuInfo = encryptData(userMenuInfo);
  localStorage.setItem("userMenuInfo", userMenuInfo);
};

// get the menu from the local storage
export const getUserMenuInfo = () => {
  const userMenus = decryptData(localStorage.getItem("userMenuInfo"));
  if (userMenus && userMenus !== 'undefined') return userMenus;
  else return null;
};

// get the menu from the local storage
export const removeUserMenuInfo = () => {
  localStorage.removeItem("userMenuInfo");
};

// set the master data from the local storage
export const setMasterDataInfo = (masterData = null) => {
  masterData = encryptData(masterData);
  localStorage.setItem("masterData", masterData);
};

// get the master data from the local storage
export const getMasterDataInfo = () => {
  const userMenus = decryptData(localStorage.getItem("masterData"));
  if (userMenus && userMenus !== 'undefined') return userMenus;
  else return null;
};

// get the master data from the local storage
export const removeMasterDataInfo = () => {
  localStorage.removeItem("masterData");
};

// set the master data from the local storage
export const setFormValuesInfo = (formValues = null) => {
  formValues = encryptData(formValues);
  localStorage.setItem("formValues", formValues);
};

// get the master data from the local storage
export const getFormValuesInfo = () => {
  const formValues = decryptData(localStorage.getItem("formValues"));
  if (formValues && formValues !== 'undefined') return formValues;
  else return {};
};

// get the master data from the local storage
export const removeFormValuesInfo = () => {
  localStorage.removeItem("formValues");
};

// set the master data from the local storage
export const setOldFormValuesInfo = (oldFormValues = null) => {
  oldFormValues = encryptData(oldFormValues);
  localStorage.setItem("oldFormValues", oldFormValues);
};

// get the master data from the local storage
export const getOldFormValuesInfo = () => {
  const oldFormValues = decryptData(localStorage.getItem("oldFormValues"));
  if (oldFormValues && oldFormValues !== 'undefined') return oldFormValues;
  else return {};
};

// get the master data from the local storage
export const removeOldFormValuesInfo = () => {
  localStorage.removeItem("oldFormValues");
};

// set the master data from the local storage
export const setFormValuesViaClassInfo = (formValuesViaClass = null) => {
  formValuesViaClass = encryptData(formValuesViaClass);
  localStorage.setItem("formValuesViaClass", formValuesViaClass);
};

// get the master data from the local storage
export const getFormValuesViaClassInfo = () => {
  const formValuesViaClass = decryptData(localStorage.getItem("formValuesViaClass"));
  if (formValuesViaClass && formValuesViaClass !== 'undefined') return formValuesViaClass;
  else return {};
};

// get the master data from the local storage
export const removeFormValuesViaClassInfo = () => {
  localStorage.removeItem("formValuesViaClass");
};

// set the FDUK from the local storage
export const setFdukInfo = (formValues = null) => {
  removeFdukInfo();
  formValues = encryptData(formValues);
  localStorage.setItem("fdukInfo", formValues);
};

// get the FDUK from the local storage
export const getFdukInfo = () => {
  const formValues =  decryptData(localStorage.getItem("fdukInfo"));
  if (formValues && formValues !== 'undefined') return formValues;
  else return { isFound: false, year: null, season: null, farmId: 0, fduk: 0, isExistingFduk: null };
};

// get the FDUK from the local storage
export const removeFdukInfo = () => {
  window.localStorage.removeItem("fdukInfo");
};

// set the announcement from the local storage
export const setAnnouncementInfo = (formValues = null) => {
  formValues = encryptData(formValues);
  localStorage.setItem("announcementInfo", formValues);
};

// get the announcement from the local storage
export const getAnnouncementInfo = () => {
  const formValues = decryptData(localStorage.getItem("announcementInfo"));
  if (formValues && formValues !== 'undefined') return formValues;
  else return null;
};

// get the announcement from the local storage
export const removeAnnouncementInfo = () => {
  window.localStorage.removeItem("announcementInfo");
};

// set the count from the local storage
export const setCountInfo = (formValues = null) => {
  formValues = encryptData(formValues);
  localStorage.setItem("countInfo", formValues);
};

// get the count from the local storage
export const getCountInfo = () => {
  const formValues = decryptData(localStorage.getItem("countInfo"));
  if (formValues && formValues !== 'undefined') return formValues;
  else return { lowCount: 0, mediumCount: 0, highCount: 0 };
};

// get the count from the local storage
export const removeCountInfo = () => {
  window.localStorage.removeItem("countInfo");
};

export const encryptData = (data = null) => {
  if(data && data !== null && data !== undefined) {
    return LZString.compress(JSON.stringify(data));
  }
  return data;
};

export const decryptData = (data = null) => {
  if(data && data !== null && data !== undefined && data !== "undefined") {
    const decompressedData = LZString.decompress(data);
    return JSON.parse(decompressedData);
  }

  return data;
};

// set the roleId from the local storage
export const setRoleIdInfo = (formValues) => {
  formValues = encryptData(formValues);
  localStorage.setItem("roleIdInfo", formValues);
};

// get the roleId from the local storage
export const getRoleIdInfo = () => {
  const formValues = decryptData(localStorage.getItem("roleIdInfo"));
  if (formValues && formValues !== 'undefined') return formValues;
  else return null;
};

// get the roleId from the local storage
export const removeRoleIdInfo = () => {
  window.localStorage.removeItem("roleIdInfo");
};

// set the adminInfo from the local storage
export const setAdminInfo = (formValues) => {
  formValues = encryptData(formValues);
  localStorage.setItem("adminInfo", formValues);
};

// get the adminInfo from the local storage
export const getAdminInfo = () => {
  const formValues = decryptData(localStorage.getItem("adminInfo"));
  if (formValues && formValues !== 'undefined') return formValues;
  else return null;
};

// get the adminInfo from the local storage
export const removeAdminInfo = () => {
  window.localStorage.removeItem("adminInfo");
};

// set the states from the local storage
export const setStatesInfo = (formValues) => {
  formValues = encryptData(formValues);
  localStorage.setItem("statesInfo", formValues);
};

// get the states from the local storage
export const getStatesInfo = () => {
  const formValues = decryptData(localStorage.getItem("statesInfo"));
  if (formValues && formValues !== 'undefined') return formValues;
  else return null;
};

// get the states from the local storage
export const removeStatesInfo = () => {
  window.localStorage.removeItem("statesInfo");
};

// set the hardRefresh from the local storage
export const setHardReloadInfo = (formValues) => {
  formValues = encryptData(formValues);
  localStorage.setItem("hardReloadInfo", formValues);
};

// get the hardRefresh from the local storage
export const getHardReloadInfo = () => {
  const formValues = decryptData(localStorage.getItem("hardReloadInfo"));
  if (formValues && formValues !== 'undefined') return formValues;
  else return null;
};

// remove the hardRefresh from the local storage
export const removeHardReloadInfo = () => {
  window.localStorage.removeItem("hardReloadInfo");
};

// set the RegisteredUsers from the local storage
export const setRegisteredUsers = (formValues) => {
  formValues = encryptData(formValues);
  localStorage.setItem("registeredUsers", formValues);
};

// get the RegisteredUsers from the local storage
export const getRegisteredUsers = () => {
  const formValues = decryptData(localStorage.getItem("registeredUsers"));
  if (formValues && formValues !== 'undefined') return formValues;
  else return null;
};

// remove the RegisteredUsers from the local storage
export const removeRegisteredUsers = () => {
  window.localStorage.removeItem("registeredUsers");
};

// set the QuestionAnsIsLegacyUpload from the local storage
export const setQuestionAnsIsLegacyUpload = (formValues) => {
  formValues = encryptData(formValues);
  localStorage.setItem("questionAnsIsLegacyUpload", formValues);
};

// get the QuestionAnsIsLegacyUpload from the local storage
export const getQuestionAnsIsLegacyUpload = () => {
  const formValues = decryptData(localStorage.getItem("questionAnsIsLegacyUpload"));
  if (formValues && formValues !== 'undefined') return formValues;
  else return null;
};

// remove the QuestionAnsIsLegacyUpload from the local storage
export const removeQuestionAnsIsLegacyUpload = () => {
  window.localStorage.removeItem("questionAnsIsLegacyUpload");
};

// set the API_RES from the local storage
export const setAllApiResponse = (formValues) => {
  formValues = encryptData(formValues);
  localStorage.setItem("allApiResponse", formValues);
};

// get the API_RES from the local storage
export const getAllApiResponse = () => {
  const formValues = decryptData(localStorage.getItem("allApiResponse"));
  if (formValues && formValues !== 'undefined') return formValues;
  else return [];
};

// remove the API_RES from the local storage
export const removeAllApiResponse = () => {
  window.localStorage.removeItem("allApiResponse");
};

// set the API_RES from the local storage
export const setCurrentLocationCoordinate = (formValues) => {
  formValues = encryptData(formValues);
  localStorage.setItem("currentCoordinates", formValues);
};

// get the API_RES from the local storage
export const getCurrentLocationCoordinate = () => {
  const formValues = decryptData(localStorage.getItem("currentCoordinates"));
  if (formValues && formValues !== 'undefined') return formValues;
  else return [];
};

// remove the API_RES from the local storage
export const removeCurrentLocationCoordinate = () => {
  window.localStorage.removeItem("currentCoordinates");
};