import { useEffect } from 'react';

const useAutoLogout = (onLogout, timeout = 20 * 60 * 1000) => {
  useEffect(() => {
    let timeoutId;

    // Reset the logout timer
    const resetTimer = () => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        onLogout();
      }, timeout);
    };

    // Event listeners for user activity
    const activityEvents = ['mousemove', 'keydown', 'mousedown', 'touchstart'];

    const addActivityListeners = () => {
      activityEvents.forEach(event =>
        window.addEventListener(event, resetTimer)
      );
    };

    const removeActivityListeners = () => {
      activityEvents.forEach(event =>
        window.removeEventListener(event, resetTimer)
      );
    };

    // Initialize the timer and listeners
    resetTimer();
    addActivityListeners();

    // Cleanup on unmount
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      removeActivityListeners();
    };
  }, [onLogout, timeout]);
};

export default useAutoLogout;
